import s from './ContactFrame.module.css';
import ContactLinksPanelContainer from "./ContactLinksPanel/ContactLinksPanelContainer";

const ContactFrame = (props) => {
    return(
        <div className={s.ContactFrame}>
            <ContactLinksPanelContainer/>
        </div>
    );
}

export default ContactFrame;