import s from './ProjectsFrame.module.css';
import ProjectsPanelContainer from "./ProjectsPanel/ProjectsPanelContainer";
import {useEffect} from "react";
import Loading from "../util/Loading/Loading";

const ProjectsFrame = (props) => {

    useEffect(() => {
        props.fetchProjects();
    }, [])

    return (
        <div className={s.ProjectsFrame}>
            {props.loading ?
                <Loading/> :
                <ProjectsPanelContainer/>
            }
        </div>
    );
}

export default ProjectsFrame;