import s from './StackFrame.module.css';
import StackListPanel from "./StackListPanel/StackListPanel";
import StackItemInformation from "./StackItemInformation/StackItemInformation";
import React, {useEffect} from "react";
import Loading from "../util/Loading/Loading";

const StackFrame = (props) => {
    const [activeElementIndex, setActiveElementIndex] = React.useState(null);

    const listPanelRef = React.useRef(null);
    const descriptionRef = React.useRef(null);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        props.fetchTechnologies();
    }, [])

    const handleClickOutside = (event) => {
        if (listPanelRef.current && !listPanelRef.current.contains(event.target) && descriptionRef.current && !descriptionRef.current.contains(event.target)) {
            setActiveElementIndex(null);
        }
    };

    let printStackListPanel = () => {
        return <>
            <StackListPanel innerRef={listPanelRef}
                            technologies={props.technologies}
                            activeElementIndex={activeElementIndex}
                            setActiveElementIndex={setActiveElementIndex}
                            error={props.error}
            />
            {props.technologies.map((tech, index) =>
                <StackItemInformation key={tech.id}
                                      innerRef={descriptionRef}
                                      technology={tech}
                                      isActive={activeElementIndex === index}/>)}
        </>

    }

    return (<div className={s.StackFrame}>
        {props.loading ? <Loading/> : printStackListPanel()}
    </div>);
}

export default StackFrame;